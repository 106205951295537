// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/theme/layout"
import Image from "../components/atoms/image"
import SEO from "../components/organisms/seo"
import styled from "styled-components"
import OtherPeople from "../components/organisms/otherpeople"

const AboutPage: React.FC<PageProps> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("someya.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - 染谷樹"
        description="1993年生まれ。2012年大学入学時にジャグリングと出会い、シガーボックスを始める。現在は靴や木枠といった⽇⽤品を扱うジャグリングを行う。台湾Future
        Circus Lab.、フラトレス第4回公演、秘密基地vol.10、CwC第7回公演等出演"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Section>
        <TitleMain>染谷樹</TitleMain>
        <TitleMain>もう一度</TitleMain>
        <MainImg filename="someya.jpg" />
        <TitleSub>素朴な疑問に向き合う</TitleSub>
        <TitleDesc>
          僕は「なぜだろう」という素朴な疑問を大切にしています。それがまだ掴めていない自分の性質を発見する一番の近道だからです。
          <br />
          <br />
          前回のぴんとくるくる浄土では、靴の演技をしました。
          <br />
          それは「なぜ道具の形を活かす必要があるのだろうか」という疑問からです。そのため形態をあえて活かさず、機能だけに着目したジャグリングを試みました。それは非常に心地良く、自分が心の奥底で求めていたジャグリングの輪郭を掴むきっかけになりました。
          <br />
          <br />
          しかし今回はぴんとくるくる浄土season2の座学/演習を通じ、その心地よさに閉じ籠らず、あえてもう一度「形態に注目する」に歩み寄ることにしました。
          <br />
          <br />
          そこで、どうせなら形態が複雑な道具を題材にとフラワースティックを選択しましたが、いざ相対してみるとまた疑問が湧きました。それは「なぜハンドスティックを使うのだろう」というものです。僕の感覚では、2種類の道具を同時に扱うことに違和感があったからです。
          <br />
          結果今回は、フラワースティックの中でも特に、ハンドスティックを使わないマルチコアの分野を考える期間になりました。
          <br />
          <br />
          振り返ると、フラワースティックマルチコアはいい題材でした。人口が少ない珍しい分野であり、流行りや基礎といった外からの情報に惑わされることが少なかったからです。そのため、ゆっくりと自分の素直な気持ちで道具と向き合うことができました。
          <br />
          <br />
          今回の『もう一度』は、数ヶ月間フラワースティックマルチコアを通じてもう一度形態と、そして自分と向き合ってみた成果です。
          <br />
          <br />
          成果発表会では、まず、参加者全員でフラワースティックマルチコアで遊んでみることで、その扱い方の違いからそれぞれのジャグリング観や性質を考えます。
          その後、その一例として『もう一度』を見ていただくことで、形態の面から、僕のジャグリング観に触れていただこうと思います。
          <br />
          <br />
          そのジャグリングはもしかすると、皆様の理想とは大きく違うかもしれません。しかしそれが、性質の違いだと僕は考えています。
          <br />
          皆様には、ぜひ、これを叩き台に各々の理想の輪郭を掴んでいただければと思います。
        </TitleDesc>
        <TitleSub>染谷樹</TitleSub>
        <TitleDesc>
          1993年生まれ。2012年にシガーボックスを始め、現在は靴や木枠といった⽇⽤品を扱うジャグリングを行う。台湾Future
          Circus Lab. open
          stage、フラトレス第4回公演、秘密基地vol.10、CwC第7回公演等出演。
        </TitleDesc>
        <TitleSub>他の参加者</TitleSub>
        <OtherPeople
          link={"/nukii"}
          name={"貫井洋介"}
          filename={"nukii.jpg"}
          link2={"/yamashita_yu"}
          name2={"山下湧志朗"}
          filename2={"yamashita_yu.jpg"}
        />
      </Section>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
const Section = styled.section`
  margin: 40px 0 60px;
  padding: 0 14px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 80px 0 60px;
  }
`

const MainImg = styled(Image)`
  maxwidth: 900;
  height: 275;
  margin: "0 auto";

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    height: 550;
  }
`

const Hr = styled.hr`
  height: 1px;
  background-color: #9a9a9a;
  width: 100%;
  border: none;
`

const TitleMain = styled.h1`
  margin: 8px auto 40px;
  font-size: 2.25rem;
  text-align: center;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const TitleSub = styled.h2`
  margin: 40px auto;
`

const TitleDesc = styled.p`
  margin: 40px 0 20px;
`
